import june_2023 from "./ಶ್ರಮಜೀವಿ ಕೃಷಿ - 2023-06.pdf";
import july_2023 from "./ಶ್ರಮಜೀವಿ ಕೃಷಿ - 2023-07.pdf";
import august_2023 from "./ಶ್ರಮಜೀವಿ ಕೃಷಿ - 2023-08.pdf";
import september_2023 from "./ಶ್ರಮಜೀವಿ ಕೃಷಿ - 2023-09.pdf";
import october_2023 from "./ಶ್ರಮಜೀವಿ ಕೃಷಿ - 2023-10.pdf";
import november_2023 from "./ಶ್ರಮಜೀವಿ ಕೃಷಿ - 2023-11.pdf";
import december_2023 from "./ಶ್ರಮಜೀವಿ ಕೃಷಿ - 2023-12.pdf";
import january_2024 from "./ಶ್ರಮಜೀವಿ ಕೃಷಿ - 2024-01.pdf";
import february_2024 from "./ಶ್ರಮಜೀವಿ ಕೃಷಿ - 2024-02.pdf";
import march_2024 from "./ಶ್ರಮಜೀವಿ ಕೃಷಿ - 2024-03.pdf";
import april_2024 from "./ಶ್ರಮಜೀವಿ ಕೃಷಿ - 2024-04.pdf";
import may_2024 from "./ಶ್ರಮಜೀವಿ ಕೃಷಿ - 2024-05.pdf";
import june_2024 from "./ಶ್ರಮಜೀವಿ ಕೃಷಿ - 2024-06.pdf";
import july_2024 from "./ಶ್ರಮಜೀವಿ ಕೃಷಿ - 2024-07.pdf";
import august_2024 from "./ಶ್ರಮಜೀವಿ ಕೃಷಿ - 2024-08.pdf";
import september_2024 from "./ಶ್ರಮಜೀವಿ ಕೃಷಿ - 2024-09.pdf";
import october_2024 from "./ಶ್ರಮಜೀವಿ ಕೃಷಿ - 2024-10.pdf";
import november_2024 from "./ಶ್ರಮಜೀವಿ ಕೃಷಿ - 2024-11.pdf";
import december_2024 from "./ಶ್ರಮಜೀವಿ ಕೃಷಿ - 2024-12.pdf";
import january_2025 from "./ಶ್ರಮಜೀವಿ ಕೃಷಿ - 2025-01.pdf";
import february_2025 from "./ಶ್ರಮಜೀವಿ ಕೃಷಿ - 2025-02.pdf";

export const editionsList = [
    // KEEP IT IN ASCENDING ORDER!
    {edition: "2023-06", filePath: june_2023},
    {edition: "2023-07", filePath: july_2023},
    {edition: "2023-08", filePath: august_2023},
    {edition: "2023-09", filePath: september_2023},
    {edition: "2023-10", filePath: october_2023},
    {edition: "2023-11", filePath: november_2023},
    {edition: "2023-12", filePath: december_2023},
    {edition: "2024-01", filePath: january_2024},
    {edition: "2024-02", filePath: february_2024},
    {edition: "2024-03", filePath: march_2024},
    {edition: "2024-04", filePath: april_2024},
    {edition: "2024-05", filePath: may_2024},
    {edition: "2024-06", filePath: june_2024},
    {edition: "2024-07", filePath: july_2024},
    {edition: "2024-08", filePath: august_2024},
    {edition: "2024-09", filePath: september_2024},
    {edition: "2024-10", filePath: october_2024},
    {edition: "2024-11", filePath: november_2024},
    {edition: "2024-12", filePath: december_2024},
    {edition: "2025-01", filePath: january_2025},
    {edition: "2025-02", filePath: february_2025}
];
